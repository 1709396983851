document.addEventListener('DOMContentLoaded', function () {
    'use strict';

	document
		.querySelector('#contactForm')
		.addEventListener('submit', onContactFormSubmitted);

    function onContactFormSubmitted (e) {
        // we want to submit the form via javascript
        e.preventDefault();

        var form = document.getElementById('contactForm');
        var action = form.action;
        var data = new FormData(form);
        var submitButton = document.querySelector('#contactForm button');
        var inputs = form.querySelectorAll('input, textarea');
        var spinner = createSpinner();

        // Disable the submit button and insert the spinner next to it
        submitButton.disabled = true;
        inputs.forEach(function (input) {
            input.disabled = true;
        });
        submitButton.insertAdjacentElement('afterend', spinner);

        // Remove previous alert messages
        var previousAlerts = form.previousElementSibling;
        while (previousAlerts && previousAlerts.classList.contains('alert')) {
            form.parentNode.removeChild(previousAlerts);
            previousAlerts = form.previousElementSibling;
        }

        // Send a POST request using Fetch API
        fetch(action, {
            method: 'POST',
            body: data,
        })
        .then(function (response) {
            return response.json();
        })
        .then(function (result) {
            if (result.response === 'error') {
                form.parentNode.insertBefore(createAlert('danger', result.message), form);
            }
            if (result.response === 'success') {
                form.parentNode.insertBefore(createAlert('success', result.message), form);
                form.reset();
            }
        })
        .catch(function (error) {
            console.error('Error:', error);
        })
        .finally(function () {
            // Enable the submit button and remove the spinner
            submitButton.disabled = false;
            inputs.forEach(function (input) {
                input.disabled = false;
            });
            submitButton.nextSibling.remove();
        });
    };

    function createAlert(type, message) {
        var alertElement = document.createElement('div');
        alertElement.className = 'alert alert-' + type;
        alertElement.innerHTML = message;
        return alertElement;
    }

    function createSpinner() {
        var spinner = document.createElement('div');
        spinner.className = 'spinner';
        return spinner;
    }
});